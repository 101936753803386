import SlickSlider from './SlickSlider';

/**
 * Component: AnchorCards
 */

export default class AwardSlider {
    constructor() {
        this.selectors = {
            root: '.award-slider',
            slider: '[data-award-slider]',
            progress: '[data-mobile-award-progress-bar]'
        };
        this.$rootElement = $(this.selectors.root);
        this.$slider = this.$rootElement.find(this.selectors.slider);
        this.$progressBar = $(this.selectors.progress);
        this.init();
    }

    init() {
        this.createSlider();
        console.log(this.$slider);
        // this.$slider.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
        //     const calc = ((nextSlide) / (slick.slideCount - 1)) * 100;
        //     this.$progressBar.css('background-position', calc + '%').attr('aria-valuenow', calc);
        // });
    }

    createSlider() {
        const config = {
            centerMode: false,
            variableWidth: false,
            infinite: false,
            draggable: true,
            arrows: false,
            dots: true,
            slidesToScroll: 1,
            slidesToShow: 3.5,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2.5
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return new SlickSlider(this.$slider, config);
    }
}
