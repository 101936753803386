/**
 * Component: Marquee
 */

export default class Marquee {
    constructor() {
        this.selectors = {
            sliders: 'data-marquee-slider',
            media: '.marquee__media',
            slide: '.marquee__slide',
            slickCurrent: '.slick-current'
        };

        this.handleSlideChange = this.handleSlideChange.bind(this);

        this.init();
    }

    /**
     * Initialize sliders
     *
     * @memberof Marquee
     */
    createSliders() {
        this.$sliders.each((index, slider) => {
            var $slider = $(slider);

            var config = {
                swipe: true,
                arrows: true,
                dots: true,
                fade: true,
                speed: 1000,
                autoplay: true,
                infinite: true,
                autoplaySpeed: 5000,
                rows: 0,
                responsive: [
                    {
                        breakpoint: 480,
                        settings: {
                            arrows: false
                        }
                    }
                ]
            };

            // add events
            this.bindSliderEvents($slider);
            // init slider
            $slider.slick(config);
        });
    }

    /**
     * Logic for slides containing video elements, image slides default to basic slick slider behavior
     * @param {*} slider: instance of slick slider
     * @memberof Marquee
     */
    handleSlideChange(slider) {
        var currentSlide;
        var mediaType;
        var video;

        currentSlide = slider.find(this.selectors.slickCurrent);
        mediaType = currentSlide.find(this.selectors.media).data('mediaType');
        var userAgent = navigator.userAgent;
        var browserName;

        if (userAgent.match(/firefox|fxios/i)) {
            browserName = 'firefox';
        }

        if (mediaType === 'video') {
            video = currentSlide.find('video').get(0);

            if (video != null) {
                if (video.paused) {
                    slider.slick('slickPause');

                    // using timeupdate so that the video continues to play as
                    // slick transitions into the next slide

                    $(video).on('timeupdate', () => {
                        var percentage = Math.floor((100 / video.duration) * video.currentTime);
                        // console.log('percentage', percentage);

                        if (percentage >= 90) {
                            slider.slick('slickPlay');
                            slider.slick('slickNext');
                            $(video).off('timeupdate'); // unbind/remove timeupdate event
                        }
                    });

                    // console.log('play marquee video');
                    video.play();
                } else if (browserName !== 'firefox') {
                    setTimeout(() => {
                        // console.log('pause marquee video');
                        video.pause();
                        video.currentTime = 0;
                    }, 1000);
                }
            }
        } else {
            slider.slick('slickPlay');
        }
    }

    /**
     * Binds events for each instance of Marquee slider
     * @param {*} $slider: instance of slick slider
     * @memberof Marquee
     */
    bindSliderEvents($slider) {
        $slider.on('init', (slick) => {
            var $slick = $(slick.currentTarget);
            setTimeout(() => {
                this.handleSlideChange($slick);
            }, 500);
        });

        $slider.on('beforeChange', (event, slick) => {
            var $slick = $(slick.$slider);
            this.handleSlideChange($slick);
        });

        $slider.on('afterChange', (event, slick) => {
            var $slick = $(slick.$slider);
            this.handleSlideChange($slick);
        });
    }

    init() {
        this.$sliders = $('[' + this.selectors.sliders + ']');

        if (this.$sliders.length) {
            this.createSliders();
        }
    }
}
