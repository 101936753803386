/**
 * Component: BackToTop
 */

 export default class BackToTop {
     constructor() {
        // selectors
         this.selectors = {
             button: '[data-back-top-button]'

         };
         this.$button = $(this.selectors.button);
         this.duration = 2000;
         this.init();
     }

     init() {
         this.$button.on('click', function (event) {
             event.preventDefault();
             $('html, body').animate({ scrollTop: 0 }, this.duration);
             return false;
         });
     }

 }
