/**
 * Component: SlickSlider
 */

export default class SlickSlider {
    constructor(element = null, config = {}) {
        this.element = element;
        this.config = config;

        this.classes = {
            light: 'slick-slider--light',
            dark: 'slick-slider--dark'
        };

        return this.init();
    }

    init() {
        if (this.element) {
            this.element = $(this.element);

            var theme = this.element.attr('data-slick-slider-theme') === 'light'
                ? 'light'
                : 'dark';

            this.element.addClass(this.classes[theme]);

            return $(this.element).not('.slick-initialized').slick(this.config);
        }

        return null;
    }
}
