import SlickSlider from './SlickSlider';

/**
 * Component: SmartOverviewSlider
 */

export default class SmartOverviewSlider {
    constructor() {
        // selectors
        this.selectors = {
            root: '.smart-overview',
            slider: '[data-smart-overview-text-slider]',
            imageSlider: '[data-smart-overview-image-slider]',
            collapseList: '[data-smart-overview-collapse]',
            collapseButtonByIndex: (index) => '[data-smart-overview-collapse-index="' + index + '"]'
        };

        // elements
        this.$rootElement = $(this.selectors.root);
        this.$textSlider = this.$rootElement.find(this.selectors.slider);
        this.$imageSlider = this.$rootElement.find(this.selectors.imageSlider);
        this.$collapseList = this.$rootElement.find(this.selectors.collapseList);

        // classes
        this.classes = {
            active: 'active'
        };

        this.init();
    }

    createSlider() {
        const config = {
            arrows: false,
            dots: true,
            fade: true,
            mobileFirst: true,
            asNavFor: this.selectors.imageSlider,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        infinite: true,
                        vertical: true,
                        dots: false,
                        fade: false,
                        slidesToScroll: 1,
                        slidesToShow: 3,
                        draggable: false,
                        focusOnSelect: true,
                        swipe: false
                    }
                }
            ]
        };

        return new SlickSlider(this.$textSlider, config);
    }

    createImageSlider() {
        const config = {
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: false,
            dots: false,
            fade: true,
            mobileFirst: true,
            asNavFor: this.selectors.slider
        };

        return new SlickSlider(this.$imageSlider, config);
    }

    updateCollapse(index) {
        var $allButtons = this.$collapseList.find('button');
        var $targetButton = this.$rootElement.find(this.selectors.collapseButtonByIndex(index));

        $allButtons.removeClass(this.classes.active);
        $targetButton.addClass(this.classes.active);
    }

    bindEvents() {
        // handle collapse list click
        this.$collapseList.on('click', 'button', (event) => {
            var $targetButton = $(event.currentTarget);
            var index = $targetButton.data('smartOverviewCollapseIndex');

            this.$imageSlider.slick('slickGoTo', index);
        });

        // handle slider initialization
        this.$imageSlider.on('init', (event, slick) => {
            var activeSlide = slick.$slider.find('.slick-active');
            var index = activeSlide.data('slickIndex');

            this.updateCollapse(index);
        });

        // handle slide change
        this.$imageSlider.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
            this.updateCollapse(nextSlide);
        });
    }

    init() {
        this.bindEvents();
        this.createImageSlider();
        this.createSlider();
    }
}
