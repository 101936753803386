/**
 * Arlo SFCC Static
 * Homepage entry point.
 */

import EmailInputModal from './components/EmailInputModal';
import SecureShopping from './components/SecureShopping';
import FeaturedReviews from './components/FeaturedReviews';
import Marquee from './components/Marquee';
import AwardSlider from './components/AwardSlider';
import HighlightCards from './components/HighlightCards';
import SmartOverviewSlider from './components/SmartOverviewSlider';
import BackToTop from './components/BackToTop';
import Footer from './components/FooterAnimate';

/**
 * Define the scroll event handler function
 */
function scrollHandler() {
    var scrollPosition = window.scrollY;
    var scrollCoordinates = window.promoOverlayPosition;
    if (scrollPosition >= scrollCoordinates) {
        // Trigger the email signup pop-up here
        new EmailInputModal('promo-overlay'); // eslint-disable-line no-new
        // Remove the scroll event listener after the modal is triggered
        window.removeEventListener('scroll', scrollHandler);
    }
}

// Attach the scroll event listener
window.addEventListener('scroll', scrollHandler);


new FeaturedReviews(); // eslint-disable-line no-new
new Marquee(); // eslint-disable-line no-new
new SmartOverviewSlider();// eslint-disable-line no-new
new AwardSlider();// eslint-disable-line no-new
new HighlightCards(); // eslint-disable-line no-new
new BackToTop(); // eslint-disable-line no-new
new SecureShopping(); // eslint-disable-line no-new
new Footer(); // eslint-disable-line no-new

