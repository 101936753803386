/**
 * Component: Footer
 */
import { gsap } from 'gsap/dist/gsap';

export default class Footer {
    constructor() {
        this.selectors = {
            root: '[data-footer]',
            panels: '[data-footer-panel]',
            panelHeadings: '[data-footer-panel-heading]',
            panelLists: '[data-footer-panel-list]'
        };

        this.rootElem = document.querySelector(this.selectors.root);
        if (!$(this.rootElem).length) return;
        this.panels = this.rootElem.querySelectorAll(this.selectors.panels);
        if (!$(this.panels).length) return;
        this.panelHeadings = this.rootElem.querySelectorAll(this.selectors.panelHeadings);
        if (!$(this.panelHeadings).length) return;

        this.init();
    }

    toggleAnimation(panelHeading) {
        // Save the current state of the clicked animation
        const selectedReversedState = panelHeading.animation.reversed();

        panelHeading.animation.reversed(!selectedReversedState);
        panelHeading.querySelector('button').setAttribute('aria-expanded', selectedReversedState);
        panelHeading.querySelector('button').classList.toggle('collapsed');
    }

    createAnimation(column) {
        const panelHeading = column.querySelector(this.selectors.panelHeadings);
        const panelList = column.querySelector(this.selectors.panelLists);

        gsap.set(panelList, { height: 'auto' });

        // Keep a reference to the animation on the column content itself
        const tween = gsap.from(panelList, {
            height: 0,
            duration: 0.25,
            ease: 'power3.inOut',
            reversed: true
        });

        panelHeading.animation = tween;
    }

    bindEvents() {
        this.panels.forEach((panel) => {
            this.createAnimation(panel);
        });

        this.panelHeadings.forEach((panelHeading) => {
            panelHeading.addEventListener('click', (e) => {
                e.stopImmediatePropagation();
                e.preventDefault();
                this.toggleAnimation(panelHeading);
            });
        });
    }

    init() {
        this.bindEvents();
    }
}
