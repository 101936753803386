import SlickSlider from './SlickSlider';

/**
 * Component: Featured Reviews
 */

export default class FeaturedReviews {
    constructor() {
        this.selectors = {
            sliders: 'data-featured-reviews-slider'
        };

        this.init();
    }

    createSliders() {
        this.$sliders.each((index, slider) => {
            var config = {
                rows: 0,
                mobileFirst: true,
                dots: true,
                arrows: false,
                centerMode: true,
                variableWidth: true,
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        centerMode: false
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        centerMode: false,
                        variableWidth: false,
                        infinite: false,
                        draggable: true,
                        arrows: true,
                        dots: false,
                        slidesToScroll: 2,
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        centerMode: false,
                        infinite: false,
                        arrows: true,
                        dots: false,
                        slidesToScroll: 1,
                        slidesToShow: 4
                    }
                }]
            };

            return new SlickSlider(slider, config);
        });
    }

    init() {
        this.$sliders = $('[' + this.selectors.sliders + ']');

        if (this.$sliders.length) {
            this.createSliders();
        }
    }
}
