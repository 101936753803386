import SlickSlider from './SlickSlider';

/**
 * Component: Highlight Cards
 */

export default class HighlightCards {
    constructor() {
        this.selectors = {
            root: '.hightlight-cards',
            slider: '[data-highlight-card-slider]'
        };
        this.$rootElement = $(this.selectors.root);
        this.$slider = this.$rootElement.find(this.selectors.slider);
        this.init();
    }

    init() {
        this.createSlider();
    }

    createSlider() {
        const config = {
            centerMode: false,
            variableWidth: false,
            infinite: false,
            draggable: true,
            arrows: false,
            dots: true,
            slidesToScroll: 1,
            slidesToShow: 1,
            rows: 0
        };
        return new SlickSlider(this.$slider, config);
    }
}
