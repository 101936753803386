import SlickSlider from './SlickSlider';

/**
 * Component: SmartOverviewSlider
 */

export default class SecureShopping {
    constructor() {
        this.selectors = {
            root: '[data-secure-shopping]',
            collapseList: '[data-secure-shopping-collapse]',
            textSlider: '[data-secure-shopping-text-slider]',
            numberSlider: '[data-secure-shopping-number-slider]',
            numberSliderButton: '[data-number-slider-button]',
            phoneSlider: '[data-secure-shopping-phone-slider]',
            progress: '[data-secure-shopping-progress-bar]',
            slideCount: '[data-secure-shopping-current-slide]',
            slideMax: '[data-secure-shopping-max-slide]',
            collapseButtonByIndex: (index) => '[data-secure-shopping-collapse-index="' + index + '"]',
            sliderButtonByIndex: (index) => '[data-secure-shopping-number-slide-index="' + index + '"]'
        };

        // classes
        this.classes = {
            active: 'active'
        };

        this.$rootElement = $(this.selectors.root);
        this.$numberSlider = this.$rootElement.find(this.selectors.numberSlider);
        this.$numberSliderButton = this.$rootElement.find(this.selectors.numberSliderButton);
        this.$textSlider = this.$rootElement.find(this.selectors.textSlider);
        this.$phoneSlider = this.$rootElement.find(this.selectors.phoneSlider);
        this.$progressBar = this.$rootElement.find(this.selectors.progress);
        this.$slideCount = this.$rootElement.find(this.selectors.slideCount);
        this.$slideMax = this.$rootElement.find(this.selectors.slideMax);
        this.$collapseList = this.$rootElement.find(
            this.selectors.collapseList
        );

        this.init();
    }

    init() {
        this.bindEvents();
        this.createTextSlider();
        this.createPhoneSlider();
        this.updateCollapse(0);
        this.updateNumberSlider(0);

        this.$phoneSlider.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
            const calc = ((nextSlide) / (slick.slideCount - Math.floor(slick.options.slidesToShow))) * 100;
            this.$progressBar.css('background-position', calc + '%').attr('aria-valuenow', calc);
        });

        this.$phoneSlider.on('afterChange', (event, slick, currentSlide) => {
            this.$slideCount.text(currentSlide + 1);
        });
    }

    updateCollapse(index) {
        var $allButtons = this.$collapseList.find('button');
        var $targetButton = this.$rootElement.find(
            this.selectors.collapseButtonByIndex(index)
        );

        $allButtons.removeClass(this.classes.active);
        $targetButton.addClass(this.classes.active);
    }

    updateNumberSlider(index) {
        const $allButtons = this.$numberSlider.find('button');
        const $targetButton = this.$rootElement.find(
            this.selectors.sliderButtonByIndex(index)
        );

        $allButtons.removeClass(this.classes.active);
        $targetButton.addClass(this.classes.active);
    }

    createPhoneSlider() {
        const config = {
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: false,
            fade: false,
            dots: false,
            asNavFor: this.$textSlider,
            slidesToScroll: 1,
            rows: 0,
            variableWidth: true,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    variableWidth: false,
                    fade: true,
                    arrows: false
                }
            }]
        };

        return new SlickSlider(this.$phoneSlider, config);
    }

    createTextSlider() {
        const config = {
            arrows: true,
            dots: false,
            asNavFor: this.$phoneSlider,
            slidesToScroll: 1,
            slidesToShow: 1,
            variableWidth: false
        };

        return new SlickSlider(this.$textSlider, config);
    }

    bindEvents() {
        // handle collapse list click
        this.$collapseList.on('click', 'button', (event) => {
            var $targetButton = $(event.currentTarget);
            var index = $targetButton.data('secureShoppingCollapseIndex');

            this.$phoneSlider.slick('slickGoTo', index);
        });

        // handle slider initialization
        this.$phoneSlider.on('init', (event, slick) => {
            var activeSlide = slick.$slider.find('.slick-active');
            var index = activeSlide.data('slickIndex');

            this.updateCollapse(index);
            this.updateNumberSlider(index);
        });

        // handle slide change
        this.$phoneSlider.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
            this.updateCollapse(nextSlide);
            this.updateNumberSlider(nextSlide);
        });

        this.$numberSliderButton.on('click', 'button', (event) => {
            var $targetButton = $(event.currentTarget);
            var index = $targetButton.data('secureShoppingNumberSlideIndex');

            this.$phoneSlider.slick('slickGoTo', index);
            this.$textSlider.slick('slickGoTo', index);
        });
    }
}
