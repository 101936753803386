/**
 * Component: EmailInputModal
 */
import Modal from './Modal';

export default class EmailInputModal {
    constructor(id) {
        this.$self = jQuery('#' + id);
        this.modalId = this.$self.data('modal-id');
        this.enableAjax = this.$self.data('modal-ajax');
        this.$initialView = this.$self.find('.view--initial');
        this.$secondaryView = this.$self.find('.view--secondary');
        this.$modalTitle = this.$self.find('.modal__title');
        this.$emailInput = this.$self.find('input[type="email"]');
        this.$placeholder = this.$self.find('span[data-placeholder]');
        this.$form = this.$self.find('form');
        this.$submitBtn = this.$self.find('button[type="submit"]');

        // Setting this here because the Modal heading is part of the Modal component
        // and not included in the markup of this EmailInputModal instance.
        this.tyMessage = 'Thanks for connecting.';

        this.setInputHandlers = this.setInputHandlers.bind(this);
        this.setSubmitHandlers = this.setSubmitHandlers.bind(this);
        this.setSecondaryView = this.setSecondaryView.bind(this);

        if (this.$self) {
            this.init();
        }
    }

    init() {
        var config = {
            showCloseButton: true,
            cookieOnSubmit: true
        };

        this.setInputHandlers();

        if (this.enableAjax === true) {
            this.setSubmitHandlers();
        }

        this.modal = new Modal(this.modalId, config); // eslint-disable-line no-new
    }

    setInputHandlers() {
        if (this.$emailInput && this.$submitBtn) {
            this.$emailInput.on('input', () => {
                this.updateInputPlaceholder(this.$emailInput.val());

                if (this.verifyEmailFormat(this.$emailInput.val())) {
                    this.$submitBtn.prop('disabled', false);
                } else {
                    this.$submitBtn.prop('disabled', true);
                }
            });
        }
    }

    setSubmitHandlers() {
        if (this.$form) {
            this.$form.on('submit', () => {
                // This will need to be set up conditionally for working with the promo-overlay and the notify on the pdp.
                // The logic below was set to work with promo overlay.
                // e.preventDefault();
                // DEV NOTE: To be called upon success call to form endpoint.
                /* $.ajax({
                    url: $(this).closest('form').attr('action'),
                    type: $(this).closest('form').attr('method'),
                    data: $(this).closest('form').serialize(),
                    dataType: 'json',
                    success: function (data) {
                        // success here
                    },
                    error: function (err) {
                        // error here
                    }
                }); */
                // this.setSecondaryView();
            });
        }
    }

    verifyEmailFormat(email) {
        var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return regex.test(String(email).toLowerCase());
    }

    updateInputPlaceholder(hasInput) {
        if (hasInput) {
            this.$placeholder.hide();
        } else {
            this.$placeholder.show();
        }
    }

    setSecondaryView() {
        // Update if initial and secondary view exist in markup.
        if (this.$initialView.length && this.$secondaryView.length) {
            // Hide initial view
            this.$initialView.hide();
            // Show success
            this.$secondaryView.show();

            // Update modal title
            this.$modalTitle.text(this.tyMessage);
        }
    }
}
